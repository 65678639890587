<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Input placeholder="请输入员工名称" v-model="storePrams.name"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker placeholder="时间查询" style="width: 100%;" v-model="storePrams.create_time"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchValue">搜索</Button>
        </div>
      </Col>
    </Row>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button v-if="jurisdictionList.indexOf('admin-works-add') !== -1" icon="md-add" type="primary" @click="addUser('add')">新增用户</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="worlkColumns" :data="data6">
      <template slot-scope="{ row, index }" slot="operation">
        <Button v-if="jurisdictionList.indexOf('admin-works-update') !== -1" type="primary" size="small" style="margin-right: 5px" @click="show(index,'edit',row)">编辑</Button>
        <Button v-if="jurisdictionList.indexOf('admin-works-del') !== -1" type="error" size="small" @click="remove(row)">删除</Button>
      </template>
      <template slot-scope="{ row }" slot="meituan">
        <Button type="info" @click="mt_amount_btn(row.id)" ghost size="small" style="margin-right: 5px">{{row.mt_amount}}</Button>
      </template>
      <template slot-scope="{ row }" slot="address">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 5px">当前状态({{ row.it_disabled=== 0 ?'已启用':'已禁用' }})</div>
          <Button type="warning" size="small" v-if="row.it_disabled === 0" style="margin-right: 5px" @click="changeOff(row)">禁用</Button>
          <Button type="success" size="small" v-else style="margin-right: 5px" @click="changeOn(row)">启用</Button>
        </div>
      </template>
    </Table>
    <br/>
    <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange"
          :page-size="storePrams.limit"/>
    <Modal
        :title="formTitle"
        v-model="showModel"
        :mask-closable="false"
        width="600"
        class-name="vertical-center-modal"
        @on-ok="submit"
        :footer-hide="true"
        @on-cancel="cancel">
      <Form ref="formItem" :rules="ruleValidate" :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="用户账户" prop="account">
          <Input v-model="formItem.account" placeholder="请输入用户账户"></Input>
        </FormItem>
        <FormItem label="用户密码" prop="pwd">
          <Input v-model="formItem.pwd" type="password" placeholder="请输入用户密码"></Input>
        </FormItem>
        <FormItem label="员工名称" prop="real_name">
          <Input v-model="formItem.real_name" placeholder="请输入用户名称"></Input>
        </FormItem>
        <FormItem label="用户号码" prop="phone">
          <Input v-model="formItem.phone" placeholder="请输入用户号码"></Input>
        </FormItem>
        <FormItem label="平台数量" prop="store_num">
          <Input v-model="formItem.store_num" type="number" placeholder="请输入可创建的平台数量"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formItem')">确认</Button>
          <Button @click="handleReset('formItem')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>



    <Modal
        :mask-closable='false'
        width="800"
        v-model="balancemodal"
        title="美团金额变动记录">
        <Form :label-width="100">
            <!-- <FormItem label="平台余额：">
              <div style="display:flex">
                <div class="num_Txt">{{platformamount}}</div>
              </div>
            </FormItem> -->
            <FormItem label="当前余额：">
              <div style="display:flex">
                <div class="num_Txt">{{mt_amount}}</div>
                <div style="margin-left:20px"><Button @click="changecli">变动</Button></div>
              </div>
            </FormItem>
            <FormItem label="日期选择：">
              <div style="display:flex">
                <div><DatePicker @on-change="time1" type="daterange" placement="bottom-start" placeholder="点击选择日期" style="width: 200px"></DatePicker></div>
                <div style="margin-left:20px"><Button @click="searchBtn1" class="btn">搜索</Button></div>
              </div>
            </FormItem>
        </Form>
        <Table ref="selection" stripe :columns="balanceColumns" :data="data5">
          <template slot-scope="{ index }" slot="index">
            <div>{{index+1}}</div>
          </template>
          <template slot-scope="{ row }" slot="scene">
            <div v-if="row.scene==1">超级管理员操作</div>
            <div v-if="row.scene==2">代理商/员工操作</div>
          </template>
        </Table>
        <br/>
        <Page :total="total1"
              :current="balancedata.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange1"
              :page-size="balancedata.limit"/>
        <div slot="footer">
            <!-- <Button @click="balancemodal = false" class="btn">关闭</Button> -->
        </div>
    </Modal>
    <Modal
        :mask-closable='false'
        v-model="changemodal"
        title="美团余额变动"
        @on-cancel="changecancel">
        <Form :label-width="100">
            <FormItem label="当前余额：">
              <div>{{mt_amount}}</div>
              <div>
                <RadioGroup v-model="balance.type">
                    <Radio :label="1">增加</Radio>
                    <Radio :label="2">减少</Radio>
                </RadioGroup>
              </div>
            </FormItem>
            <FormItem label="余额变动：">
              <Input v-model="balance.val" placeholder="请输入余额"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button @click="changecancel">取消</Button>
            <Button type="primary" @click="changeadd">确认</Button>
        </div>
    </Modal>




  </div>
</template>
<script>
import {staffBan, staffList,staffAdd, staffInfo,staffEdit,staffDel} from '@/api/operation'
import {getMtList,mtHandle} from '../../api/agent'
import moment from "moment";
export default {
  data () {
    var checkphone = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入手机号'))
          } else if (!this.isCellPhone(value)) {
            callback(new Error('请输入正确的手机号!'))
          } else {
            callback()
          }
        };
    return {
      worlkColumns: [
        {
          title: '用户账户',
          key: 'account'
        },
        {
          title: '员工名称',
          key: 'real_name'
        },
        {
          title: '平台数量',
          key: 'store_num'
        },
        {
          title: '员工状态',
          slot:'address'
        },
        {
          title: '美团余额',
          width: 100,
          slot: 'meituan'
        },
        {
          title: '创建时间',
          key: 'created_at'
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slot: 'operation'
        }
      ],
      balancemodal:false,
      changemodal:false,
      total1:0,
      mt_amount:0,
      balancedata:{
        id:null,
        page:1,
        limit:5,
        start_time:'',
        end_time:''
      },
      data5:[],
      balanceColumns:[
        {
          title: '#',
          slot: 'index',
          align: 'center',
          width: 60
        },
        {
          title: '时间',
          key: 'created_at',
          align: 'center',
        },
        {
          title: '金额',
          key: 'money',
          align: 'center',
        },
        {
          title: '类型',
          slot: 'scene',
          align: 'center',
        },
        {
          title: '说明',
          key: 'desc',
          align: 'center',
        }
      ],
      balance:{
        id:null,
        val:'',
        type:1,
      },
      data6: [],
      showModel:false,
      formItem:{
        account:'',
        pwd:'',
        real_name:'',
        phone:'',
        store_num:'',
      },
      ruleValidate: {
        account: [
          { required: true, message: '请输入用户账户', trigger: 'blur' }
        ],
        // pwd: [
        //   { required: true, message: '请输入用户密码', trigger: 'blur' }
        // ],
        real_name: [
          { required: true, message: '请输入员工名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: checkphone,trigger: 'blur' },
        ],
        store_num: [
          { required: true, message: '请输入平台数量', trigger: 'blur' }
        ]
      },
      formTitle:'新增用户',
      total:0,
      storePrams: {
        limit: 10,
        page: 1,
        name: '',
        create_time: ''
      },
      jurisdictionList:[]
    }
  },
  created() {
    this.getList()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted(){
  },
  methods: {
    isCellPhone (val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    },
    show (index,type,row) {
      if(type === 'edit'){
        this.formTitle='编辑用户信息'
      }
      staffInfo({staff_id:row.id}).then(res=>{
        this.formItem.account =res.data.account
        this.formItem.pwd = res.data.pwd
        this.formItem.real_name =res.data.real_name
        this.formItem.phone =res.data.phone
        this.formItem.staff_id = res.data.id
        this.formItem.store_num = String(res.data.store_num)
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
      this.showModel=true
    },
    searchValue(){
      this.storePrams.create_time = moment(this.storePrams.create_time).format('YYYY-MM-DD')
      if (this.storePrams.create_time == 'Invalid date') {
        this.storePrams.create_time = ''
      }
      this.getList()
    },
    mt_amount_btn(e){
      this.balancedata.id = e
      this.balancemodal = true
      this.mtAmountList()
    },
    searchBtn1(){
      this.mtAmountList()
    },
    mtAmountList(){
      getMtList(this.balancedata).then(res=>{
        this.total1 = res.data.total
        this.data5 = res.data.data
        this.mt_amount = res.data.mt_amount
      })
    },
    changecancel(){
      this.balance.type = 1
      this.changemodal = false
    },
    time1(e){
      this.balancedata.start_time = e[0]
      this.balancedata.end_time = e[1]
    },
    pageChange1(index){
      this.balancedata.page = index
      this.mtAmountList()
    },
    changecli(){
      this.balance.id = this.balancedata.id
      this.changemodal = true
    },
    changeadd(){
      mtHandle(this.balance).then(res=>{
        this.mtAmountList()
        this.getList()
        this.changemodal = false
        this.balance.val = ''
        this.balance.type = 1
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    getList(){
      staffList(this.storePrams).then(res=>{
        this.data6 = res.data.data
        this.total = res.data.total
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    changeOff(row){
      this.$Modal.confirm({
        title: '禁用提示',
        content: '请问您是否确认禁用此条用户',
        onOk: () => {
          if(row.it_disabled === 0){
            let changeType = 1
            staffBan({user_id:row.id,status:changeType}).then(res=>{
              this.getList()
              this.$Message.success(res.msg)
            }).catch(res=>{
              this.$Message.error(res.msg)
            })
          }
        },
        onCancel: () => {
        }
      });
    },
    changeOn(row){
      this.$Modal.confirm({
        title: '启用提示',
        content: '请问您是否确认启用此条用户',
        onOk: () => {
          if(row.it_disabled === 1){
            let changeType = 0
            staffBan({user_id:row.id,status:changeType}).then(res=>{
              this.getList()
              this.$Message.success(res.msg)
            }).catch(res=>{
              this.$Message.error(res.msg)
            })
          }
        },
        onCancel: () => {
        }
      });
    },
    remove(row) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条用户，删除后无法恢复！！！',
        onOk: () => {
          staffDel({staff_id:row.id}).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res=>{
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    addUser(type){
      if(type === 'add'){
        this.formTitle='新增用户'
        this.reset()
      }
      this.showModel = true
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if(this.formItem.staff_id){
            staffEdit(this.formItem).then(res=>{
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel=false
            }).catch(res=>{
              this.$Message.error(res.msg)
              this.reset()
            })
          }else {
            staffAdd(this.formItem).then(res=>{
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel=false
            }).catch(res=>{
              this.$Message.error(res.msg)
              this.reset()
            })
          }

        } else {
          this.$Message.error('请输入用户信息');
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    submit(){

    },
    cancel(){

    },
    reset(){
      this.formItem.account =''
      this.formItem.pwd = ''
      this.formItem.real_name = ''
      this.formItem.phone = ''
      this.formItem.store_num = ''
      this.formItem.staff_id = undefined
    }
  }
}
</script>
